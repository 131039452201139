/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它 
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
// init oltConsole
export class oltUserInfo{

  constructor () {
    //const oltConsole = new oce() ; 
    oltConsole.log('oltUserInfo!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this

  // 記錄 之後 想要回來的 地方 ， 例如 登入後回到強制登入頁 
  setUserInfo(store,Obj){
    //console.log('oUrl locationUrl test :  ');
    store.dispatch('setUserInfo',Obj);
    //console.log(store.state);
  }

  cleanUserInfo(store){
    store.dispatch('cleanUserInfo');
    //console.log(store.state);
  }

  getUserInfo(store){
    if(store.getters.isUserInfo){
      return store.state.userInfo.userInfo;
    }
    return false ; 
  }

  setTimerHeaderViewId(store,id){
    store.dispatch('setTimerHeaderViewId',id);
  }

  getTimerHeaderViewId(store){
    return store.state.userInfo.timer.headerViewId;
  }

  // 購物車
  getShoppingCart(store){
    if(store.getters.isShoppingCart){
      return store.state.userInfo.shoppingCart;
    }
    return false ; 
  }
  setShoppingCart(store, Obj){
    store.dispatch('setShoppingCart',Obj);
  }
  clearShoppingCart(store){
    store.dispatch('clearShoppingCart');
  }
  deleteOneShoppingCart(store, id){
    store.dispatch('deleteOneShoppingCart', id);
  }
  setOneShoppingCart(store, Obj){
    store.dispatch('setOneShoppingCart', Obj);
    store.dispatch('plusOneShoppingCartNum');
  }
  deletePositionShoppingCart(store, position){
    store.dispatch('deletePositionShoppingCart', position);
    store.dispatch('minusOneShoppingCartNum');
  }
  // 第三方登入
  setOpenid(store, Obj){
    store.dispatch('setOpenid', Obj);
  }
  getOpenid(store){
    if(store.getters.isOpenid){
      return store.state.userInfo.openid;
    }
    return false ;
  }
  clearOpenid(store){
    store.dispatch('clearOpenid');
  }
  // 金流
  setPayment(store, Obj){
    store.dispatch('setPayment', Obj);
  }
  getPayment(store){
    if(store.getters.isPayment){
      return store.state.userInfo.payment;
    }
    return false ;
  }
  clearPayment(store){
    store.dispatch('clearPayment');
  }
  // 知識點
  getKnowledgeVideo(store){
    return store.getters.isKnowledgeVideo; 
  }
  setOneKnowledgeVideo(store, Obj){
    store.dispatch('setOneKnowledgeVideo', Obj);
  }
  clearKnowledgeVideo(store){
    store.dispatch('clearKnowledgeVideo');
  }

  // 個人訂單細項 oiid
  getTempID(store){
    return store.getters.isTempID; 
  }
  setTempID(store, Obj){
    store.dispatch('setTempID', Obj);
  }
  clearTempID(store){
    store.dispatch('clearTempID');
  }

  // 首頁 - 填寫個人資訊視窗
  getHomeProfileCheck(store){
    if (store.getters.isHomeProfileCheck && store.state.userInfo.homeProfileCheck != null){
      return store.state.userInfo.homeProfileCheck;
    }
    return '';
  }
  setHomeProfileCheck(store, Obj){
    store.dispatch('setHomeProfileCheck', Obj);
  }
  clearHomeProfileCheck(store){
    store.dispatch('clearHomeProfileCheck');
  }
  // 付款方式選擇
  getPaymentChose(store){
    if (store.getters.isPaymentChose && store.state.userInfo.paymentChose != null){
      return store.state.userInfo.paymentChose;
    }
    return '';
  }
  setPaymentChose(store, Obj){
    store.dispatch('setPaymentChose', Obj);
  }
  clearPaymentChose(store){
    store.dispatch('clearPaymentChose');
  }
  
  // 考卷 ID
  setEpid(store, id){
    store.dispatch('setEpid', id);
  }
  getEpid(store) {
    let id = '';

    if (store.getters.isEpid) {
      id = store.state.userInfo.epid;
    }
    return id;
  }
  // 課程名稱
  setCourseName(store, name){
    store.dispatch('setCourseName', name);
  }
  getCourseName(store) {
    let name = '';

    if (store.getters.isCourseName) {
      name = store.state.userInfo.courseName;
    }
    return name;
  }
  // 題庫名稱
  setExerciseName(store, name){
    store.dispatch('setExerciseName', name);
  }
  getExerciseName(store) {
    let name = '';

    if (store.getters.isExerciseName) {
      name = store.state.userInfo.exerciseName;
    }
    return name;
  }
  // 電子書名稱
  setEbookName(store, name){
    store.dispatch('setEbookName', name);
  }
  getEbookName(store) {
    let name = '';

    if (store.getters.isEbookName) {
      name = store.state.userInfo.ebookName;
    }
    return name;
  }
  // 伺服器參數
  setServerInfoConfig(store, Obj){
    store.dispatch('setServerInfoConfig', Obj);
  }
  clearServerInfoConfig(store){
    store.dispatch('clearServerInfoConfig');
  }
  getServerInfoConfig(store) {
    if (store.getters.isServerInfoConfig) {
      return store.state.userInfo.serverInfoConfig;
    }
    return null;
  }
  
  // 設定畫面轉跳權限資料
  setRouterViewPermission(store, Obj){
    store.dispatch('setRouterViewPermission', Obj);
  }
  // 清空畫面轉跳權限資料
  clearRouterViewPermission(store){
    store.dispatch('clearRouterViewPermission');
  }
  // 取得畫面轉跳權限資料
  getRouterViewPermission(store) {
    if (store.getters.isRouterViewPermission) {
      return store.state.userInfo.routerViewPermission;
    }
    return null;
  }
  // 檢查是否有該畫面轉跳權限
  checkRouterViewPermission(store, view) {
    let res = false;
    let routerViewPermissionList = this.getRouterViewPermission(store);

    if(routerViewPermissionList != null && routerViewPermissionList.length > 0){
      
      for (let i = 0 ; i < routerViewPermissionList.length ; i++) {
        let temp = routerViewPermissionList[i];

        if(temp != null && temp.vid_code != null && temp.vid_code == view){
          res = true;
          break;
        }
      }
    }
    return res;
  }  
  
  // 設定 router 錯誤訊息
  setRouterErrorMessage(store, value){
    store.dispatch('setRouterErrorMessage', value);
  }
  // 清空 router 錯誤訊息
  clearRouterErrorMessage(store){
    store.dispatch('clearRouterErrorMessage');
  }
  // 取得 router 錯誤訊息
  getRouterErrorMessage(store) {
    if (store.getters.isRouterErrorMessage) {
      return store.state.userInfo.routerErrorMessage;
    }
    return null;
  }
  
  // 設定暫存活動序號
  setTempVoucher(store, value){
    store.dispatch('setTempVoucher', value);
  }
  // 清空暫存活動序號
  clearTempVoucher(store){
    store.dispatch('clearTempVoucher');
  }
  // 取得暫存活動序號
  getTempVoucher(store) {
    if (store.getters.isTempVoucher) {
      return store.state.userInfo.tempVoucher;
    }
    return null;
  }

  // 設定目前影片資訊
  setCurrentVideo(store, value){
    store.dispatch('setCurrentVideo', value);
  }
  // 清空目前影片資訊
  clearCurrentVideo(store){
    store.dispatch('clearCurrentVideo');
  }
  // 取得目前影片資訊
  getCurrentVideo(store) {
    if (store.getters.isCurrentVideo) {
      return store.state.userInfo.currentVideo;
    }
    return null;
  }
}