/* eslint-disable camelcase */
const podschool = { 
  //網頁
  WebName: 'Podschool',
  WebYear: '2022',
  WebLogo: 'assets/img/logo3.svg',
  HeaderLogo: 'assets/img/logo3.png',
  FooterLogo: 'assets/img/logo3.png',
  WebPhone: '02-6635-5889',
  //購物車
  ShoppingIcon: 'assets/img/right-cart.png',
  //導引
  GuideLogo: 'assets/img/hlwebfont.png',
  GuideIcon: 'assets/img/right-web 2.png',
  //QA
  QaLogo: 'assets/img/right-QA 2.png',
  QaFont: 'assets/img/hlqafont.png',
  QaBackground: 'assets/img/qafont.png',
  //sell
  SellLogo: 'assets/img/right-line.png',
  //點數
  PointName: 'PodPoint',
  PointSuffix: 'PP',
  PointClass: 'text-info',
  //Header
  HeaderLessonBuyName: '課程頻道',
  HeaderVoucherName: '活動序號',
  HeaderNotifyName: '通知',
  HeaderDefaultName: '進入學習',
  HeaderAccount: '帳號設定',
  HeaderAccountClass: 'fa-gear',
  HeaderOrder: '訂單紀錄',
  HeaderOrderClass: 'fa-list',
  HeaderLesson: '我的課程',
  HeaderLessonClass: 'fa-regular fa-book',
  HeaderHistory: '學習記錄',
  HeaderHistoryClass: 'fa-file-certificate',
  HeaderMapClass: 'fa-file-certificate',
  HeaderMessage: '管家留言',
  HeaderMessageClass: 'fa-glasses-round',
  HeaderCollect: '我的收藏',
  HeaderCollectClass: 'fa-heart',
  //主帳號登入
  UserProfileMain: '主帳號管理',
  MainName: '會員',
  SubMemberManage: '帳號學員管理',
  SubMemberName: '學員',
  SubMemberLoginName: '學員',
  UserPoints: 'PodPoint管理',
  //子帳號登入
  SubUserProfileMain: '學員帳號管理',
  //訂單
  OrderClass: '',
  //我的課程
  MyLesson: '學習計畫',
  MyLessonIcon: 'fa-graduation-cap',
  ReviewLesson: '課程回顧',
  ChooseContent: '請先選擇學員或新增學員',
  //學習紀錄
  History: '學習記錄',
  HistoryStyle: 'background-image:url(assets/img/blackboadd.jpg)',
  //學習地圖
  MapAccountName: '會員/學員',
  //管家留言
  MessageTitle: 'PodSchool管家',
  MessageBottom: '管家服務時間為週一～週六14:00-21:30，服務時間內會盡快回覆同學呦！',
  //我的收藏
  CollectExam: '題目收藏',
  //序號
  VouchersBGClass: 'bg-warning',
  //Footer
  AboutUs: '關於我們',
  NewsList: '新聞媒體中心',
  ServeContract: '服務契約',
  Privacy: '隱私權策略',
  Questions: '常見問題',
  ContactUs: '聯絡我們',
  Facebook: '口袋粉絲',

};



export { podschool };
