// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';

// init oltConsole
export class oltSBMeta {
  constructor() {
    oltConsole.log('Init sbmeta!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this
  setSBMeta(store, sbmeta) {
    store.dispatch('setSBMeta', sbmeta);
  }
  // eslint-disable-next-line class-methods-use-this
  getSBMeta(store){
    if(store.getters.isSBMeta){
      return store.state.sbmeta.sbmeta;
    }
    return false ; 
  }
  // eslint-disable-next-line class-methods-use-this
  cleanSBMeta(store) {
    store.dispatch('cleanSBMeta');
  }
}
