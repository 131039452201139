/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
import { oltCookie } from '@/lib/oltCookie';
export const state = {
  // 重新 Reload 瀏覽器時 會從 sessionStorage 放進 state.
  user: oltEncrypt.storeDecodeObject(sessionStorage.getItem('user')),
  signup: oltEncrypt.storeDecodeText(sessionStorage.getItem('signup'))
};
/*
mutations 必須是同步執行，而 actions 則可以異步執行。
*/
export const actions = {
  setSBMetaUser({ commit }, user) {
    commit('setSBMetaUser', user);
  },
};

/*
Mutations其實就是包含著一堆函數的物件，每一個mutation就是一個函數，負責接收actions並更改state的資料，其中有幾個注意事項。

mutation必定是同步函數，沒有例外
mutation是vuex中唯一可以改動state的方法
也就是說在vuex中，如果你要改變state狀態，你一定要經過以下的流程

發出一個action(dispatch an action)
接收到action後，執行對應的mutation (commit a mutation)
透過mutation更改state
*/
export const mutations = {
  loginSuccess(state, user) {
    //console.log('commit loginSuccess : ');
    //console.log(user);
    sessionStorage.setItem('user', oltEncrypt.storeEncodeObject(user));
    // localStorage.setItem('user', oltEncrypt.storeEncodeObject(user));
    let isAccept = oltCookie.getAcceptCookie();
    if(isAccept && isAccept === 'accept'){
      document.cookie = "user=" + oltEncrypt.storeEncodeObject(user) + ";" + "expires=" + oltCookie.cookieExpires() + ";path=/";
    }
    state.user = user;
  },
  logout(state) {
    sessionStorage.removeItem('user');
    // localStorage.removeItem('user');
    let isAccept = oltCookie.getAcceptCookie();
    if(isAccept && isAccept === 'accept'){
      oltCookie.setCookie('user');
    }
    state.user = null;
  },
  signupToRegister(state, phone) {
    sessionStorage.setItem('signup', oltEncrypt.storeEncodeText(phone));
    // localStorage.setItem('signup', oltEncrypt.storeEncodeText(phone));
    let isAccept = oltCookie.getAcceptCookie();
    if(isAccept && isAccept === 'accept'){
      document.cookie = "signup=" + oltEncrypt.storeEncodeText(phone) + ";" + "expires=" + oltCookie.cookieExpires() + ";path=/";
    }
    state.signup = phone;
  },
  cleanRegister(state, phone) {
    sessionStorage.removeItem('signup');
    // localStorage.removeItem('signup');
    let isAccept = oltCookie.getAcceptCookie();
    if(isAccept && isAccept === 'accept'){
      oltCookie.setCookie('signup');
    }
    state.signup = null;
  },
  updateStateUser(state, user){
    state.user = user;
  },
  updateStateSignup(state, phone){
    state.signup = phone;
  },
  setSBMetaUser(state, user) {
    sessionStorage.setItem('user', oltEncrypt.storeEncodeObject(user));
    state.user = user;
  },
  /*
  setUser(state, payload) {
    sessionStorage.setItem('user', oltEncrypt.storeEncodeObject(payload));
    state.user = payload;
  },
  */
};

// 取得目前的登入狀態
export const getters = {
  //isAuthenticated: state => Boolean(state.user) || Boolean(sessionStorage.getItem('user')),
  isLogin(state) {
    if (state.user) {
      return true;
    }
    return false;
  },
  isSignup() {
    if (state.signup) {
      return true;
    }
    return false;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
