import { createStore } from 'vuex';
//import auth from './localAuth';
import auth from './auth';
import userInfo from './userInfo';
import url from './url';
import guestAuth from './guestAuth';
import openid from './openid';
import webInfo from './webInfo';
import sbmeta from './sbmeta';

const defaultState = {
  user: null,
  signup: null,  
};
    
// Create a new store instance.
export default createStore({
  state() {
    return defaultState ; 
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules: { auth , url , userInfo , guestAuth , openid , webInfo, sbmeta },
});
