/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  // 重新 Reload 瀏覽器時 會從 sessionStorage 放進 state.
  locationUrl: oltEncrypt.storeDecodeText(sessionStorage.getItem('locationUrl')),
  urlQuery: oltEncrypt.storeDecodeText(sessionStorage.getItem('urlQuery')),
  urlParams: oltEncrypt.storeDecodeText(sessionStorage.getItem('urlParams')),
  routerHistory: oltEncrypt.storeDecodeObject(sessionStorage.getItem('routerHistory')),
  routerQuery: oltEncrypt.storeDecodeObject(sessionStorage.getItem('routerQuery')),
  routerHistoryIndex: oltEncrypt.storeDecodeText(sessionStorage.getItem('routerHistoryIndex')),
};

/*
mutations 必須是同步執行，而 actions 則可以異步執行。
*/
export const actions = {
  // 使用 Action 寫入 location URl
  locationUrl({ commit }, urlObj) {
    //console.log('action url : ' , urlObj);
    commit('locationUrl', urlObj);
  },
  cleanLocationUrl({ commit }) {
    //console.log('action url : ' , urlObj);
    commit('cleanLocationUrl');
  },

  urlQuery({ commit }, urlObj) {
    commit('urlQuery', urlObj);
  },
  cleanUrlQuery({ commit }) {
    commit('cleanUrlQuery');
  },

  urlParams({ commit }, urlObj) {
    commit('urlParams', urlObj);
  },
  cleanUrlParams({ commit }) {
    commit('cleanUrlParams');
  },

  setRouterQuery({ commit }, object) {
    commit('setRouterQuery', object);
  },
  cleanRouterQuery({ commit }) {
    commit('cleanRouterQuery');
  },

  setRouterHistory({ commit }, array) {
    commit('setRouterHistory', array);
  },
  cleanRouterHistory({ commit }) {
    commit('cleanRouterHistory');
  },

  setRouterHistoryIndex({ commit }, index) {
    commit('setRouterHistoryIndex', index);
  },
  cleanRouterHistoryIndex({ commit }) {
    commit('cleanRouterHistoryIndex');
  },

};

/*
Mutations其實就是包含著一堆函數的物件，每一個mutation就是一個函數，負責接收actions並更改state的資料，其中有幾個注意事項。

mutation必定是同步函數，沒有例外
mutation是vuex中唯一可以改動state的方法
也就是說在vuex中，如果你要改變state狀態，你一定要經過以下的流程

發出一個action(dispatch an action)
接收到action後，執行對應的mutation (commit a mutation)
透過mutation更改state
*/
export const mutations = {
  locationUrl(state, urlObj) {
    //state.locationUrl = window.btoa(locationUrl) ;
    state.locationUrl = urlObj;
  },
  cleanLocationUrl(state) {
    state.locationUrl = null;
  },

  urlQuery(state, urlObj) {
    state.urlQuery = urlObj;
  },
  cleanUrlQuery(state) {
    state.urlQuery = null;
  },

  urlParams(state, urlObj) {
    state.urlParams = urlObj;
  },
  cleanUrlParams(state) {
    state.urlParams = null;
  },

  setRouterQuery(state, object) {
    if(state.routerQuery == null){
      state.routerQuery = {};
    }
    if(object != null){
      state.routerQuery = object;
      sessionStorage.setItem('routerQuery', oltEncrypt.storeEncodeObject(state.routerQuery));
    }
  },
  cleanRouterQuery(state) {
    sessionStorage.removeItem('routerQuery');
    state.routerQuery = {};
  },

  setRouterHistory(state, array) {
    if(state.routerHistory == null){
      state.routerHistory = [];
    }
    if(array != null){
      state.routerHistory = array;
      sessionStorage.setItem('routerHistory', oltEncrypt.storeEncodeObject(state.routerHistory));
    }
  },
  cleanRouterHistory(state) {
    sessionStorage.removeItem('routerHistory');
    state.routerHistory = [];
  },

  setRouterHistoryIndex(state, index) {
    state.routerHistoryIndex = index;
    sessionStorage.setItem('routerHistoryIndex', oltEncrypt.storeEncodeText(state.routerHistoryIndex));
  },
  cleanRouterHistoryIndex(state) {
    sessionStorage.removeItem('routerHistoryIndex');
    state.routerHistoryIndex = 0;
  },
};

export const getters = {
  //isAuthenticated: state => Boolean(state.user) || Boolean(sessionStorage.getItem('user')),
  isLocationUrl(state) {
    if (state.locationUrl) {
      return true;
    }
    return false;
  },

  isUrlQuery(state) {
    if (state.urlQuery) {
      return true;
    }
    return false;
  },

  isUrlParams(state) {
    if (state.urlParams) {
      return true;
    }
    return false;
  },

  isRouterQuery(state) {
    if (state.routerQuery) {
      return true;
    }
    return false;
  },

  isRouterHistory(state) {
    if (state.routerHistory) {
      return true;
    }
    return false;
  },

  isRouterHistoryIndex(state) {
    if (state.routerHistoryIndex) {
      return true;
    }
    return false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
