/* eslint-disable no-shadow */
import { modulesList } from '@/include/config';
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  // 重新 Reload 瀏覽器時 會從 sessionStorage 放進 state.
  webType: oltEncrypt.storeDecodeText(sessionStorage.getItem('webType')),
  subDomain: oltEncrypt.storeDecodeText(sessionStorage.getItem('subDomain')),
};

export const actions = {
  setSubDomain({ commit }, value) {
    commit('setSubDomain', value);
  },
  cleanSubDomain({ commit }) {
    commit('cleanSubDomain');
  },
  setWebType({ commit }, value) {
    commit('setWebType', value);
  },
  cleanWebType({ commit }) {
    commit('cleanWebType');
  },
};

/*
Mutations其實就是包含著一堆函數的物件，每一個mutation就是一個函數，負責接收actions並更改state的資料，其中有幾個注意事項。

mutation必定是同步函數，沒有例外
mutation是vuex中唯一可以改動state的方法
也就是說在vuex中，如果你要改變state狀態，你一定要經過以下的流程

發出一個action(dispatch an action)
接收到action後，執行對應的mutation (commit a mutation)
透過mutation更改state
*/
export const mutations = {
  setSubDomain(state, value) {
    sessionStorage.setItem('subDomain', oltEncrypt.storeEncodeText(value));
    state.subDomain = value;
  },
  
  cleanSubDomain(state) {
    sessionStorage.removeItem('subDomain');
    state.subDomain = null;
  },

  setWebType(state, value) {
    if(modulesList != null && modulesList.checkSubDomain != null && modulesList.checkSubDomain == true &&
        modulesList.subDomainList != null && modulesList.subDomainList.length > 0){
      for (const [k, v] of Object.entries(modulesList.subDomainList)) {
        if(v == null || v.domain == null || v.domain != value || v.key == null){
          continue;
        }
        sessionStorage.setItem('webType', oltEncrypt.storeEncodeText(v.key));
        state.webType = v.key;
      }
  
      if(state.webType == null || state.webType == ''){
        // 預設值
        sessionStorage.setItem('webType', oltEncrypt.storeEncodeText('general'));
        state.webType = 'general';
      }
    }
    else{
      // 預設值
      sessionStorage.setItem('webType', oltEncrypt.storeEncodeText('general'));
      state.webType = 'general';
    }
  },
  
  cleanWebType(state) {
    sessionStorage.removeItem('webType');
    state.webType = null;
  },

};


export const getters = {
  isSubDomain(state) {
    if (state.subDomain) {
      return true;
    }
    return false;
  },
  isWebType(state) {
    if (state.webType) {
      return true;
    }
    return false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
