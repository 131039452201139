/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它 
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
// init oltConsole
export class oltWebInfo{

  constructor () { 
    oltConsole.log('oltWebInfo!!!');
  }
  
  setSubDomain(store,value){
    store.dispatch('setSubDomain',value);
  }

  cleanSubDomain(store){
    store.dispatch('cleanSubDomain');
  }
  
  getSubDomain(store){
    if (store.getters.isSubDomain) {
      return store.state.webInfo.subDomain;
    }
    return null;
  }  
  
  setWebType(store,value){
    store.dispatch('setWebType',value);
  }

  cleanWebType(store){
    store.dispatch('cleanWebType');
  }

  checkSubDomain(store) {
    return store.getters.isSubDomain;
  }
  
  isWebTypeGeneral(store){
    if (store.getters.isWebType) {
      if(store.state.webInfo.webType == null || store.state.webInfo.webType == ''){
        // 因為是預設值，所以當值為空時，回傳 true
        return true;
      }
      else if(store.state.webInfo.webType != null && store.state.webInfo.webType == 'general'){
        // 當值為 general 時，符合為預設值，回傳 true
        return true;
      }
      return false;
    }
    return true; // 因為是預設值，所以回傳 true
  }
  
  isWebTypeLite(store){
    if (store.getters.isWebType) {
      if(store.state.webInfo.webType != null && store.state.webInfo.webType == 'lite'){
        return true;
      }
      return false;
    }
    return false;
  }
}