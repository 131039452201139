/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { oltConsole } from '@/lib/oltModule/oltConsole';
import { config, restApiDriver, defaultYearCode, modulesList } from '@/include/config';
import { oltEncrypt } from '@/lib/oltEncrypt';

class oltExternalConfigModule {
  /*
  constructor() {
    oltConsole.log('oltExternalConfig constructor');
  }
  */

  loadExternalConfig() {
    //oltConsole.log('oltExternalConfig loadExternalConfig...');

    this.getExternalConfig();
  }

  getExternalConfig() {
    try {
      fetch(config.externalConfigPath)
        .then(res => res.text())
        .then(data => (
          this.convertExternalConfig(data)
        ));
    } catch (e) {
      return false;
    }
    return true;
  }

  convertExternalConfig(value){
    //console.log('convertExternalConfig');
    //console.log('value : ', value);
    if(value == null || value == ''){
      return;
    }

    let obj = oltEncrypt.decodeConfigObject(value);

    //console.log('obj : ', obj);
   
    if(obj != null){
      if(obj.restApiDriver != null){
        this.updateRestApiDriver(obj.restApiDriver);
      }
      if(obj.defaultYearCode != null){
        this.updateDefaultYearCode(obj.defaultYearCode);
      }
      if(obj.modulesList != null){
        this.updateModulesList(obj.modulesList);
      }
    }

  }

  updateRestApiDriver(value){
    if(restApiDriver != null && value != null){
      for (const [k, v] of Object.entries(value)) {
        //console.log('k : ', k); console.log('v : ', v); exit;

        // 強制覆蓋
        restApiDriver[k] = v;
      }
    }
    //console.log('restApiDriver : ', restApiDriver);
  }  

  updateDefaultYearCode(value){
    if(defaultYearCode != null && value != null){
      for (const [k, v] of Object.entries(value)) {
        //console.log('k : ', k); console.log('v : ', v); exit;

        // 強制覆蓋
        defaultYearCode[k] = v;
      }
    }
    //console.log('defaultYearCode : ', defaultYearCode);
  }

  updateModulesList(value){
    if(modulesList != null && value != null){
      for (const [k, v] of Object.entries(value)) {
        //console.log('k : ', k); console.log('v : ', v); exit;

        // 強制覆蓋
        modulesList[k] = v;
      }
    }
    //console.log('modulesList : ', modulesList);
  }

  createTestCase(){
    let restApiDriver = {
      defaultApiUrl : 'http://172.16.3.21:5080/api' ,
      dotnetApiUrl  : 'http://172.16.3.21:5080/api' ,
      phpApiUrl  : 'http://172.16.3.21/ClientApi/api' ,
    };

    let defaultYearCode = {
      num: '111',
    };

    let modulesList = {
      promo: false,
      routerViewPermission: false,
    };

    let tempConfig = {
      restApiDriver : restApiDriver,
      defaultYearCode : defaultYearCode,
      modulesList : modulesList,
    };

    console.log('tempConfig : ', tempConfig);

    let encode = oltEncrypt.encodeConfigObject(tempConfig);

    console.log('encode : ', encode);
    let decode = oltEncrypt.decodeConfigObject(encode);

    console.log('decode : ', decode);
    
  }
}

// eslint-disable-next-line new-cap
const oltExternalConfig = new oltExternalConfigModule();

export { oltExternalConfig };
