<template>
  <!--
  <Loading
    :active="isLoading"
    :is-full-page="true"
    :width="42"
    loader="spinner"
    :lock-scroll="false"
  />
  -->

  <template 
    v-if="isLoading == true"
  >
    <div class="vh-100 d-flex align-items-center justify-content-center">
      <div>
        <div class="load1">
          <div class="gear one">
            <img src="assets/img/gearb.svg">
          </div>
          <div class="gear two">
            <img src="assets/img/gearp.svg">
          </div>
          <div class="gear three">
            <img src="assets/img/geary.svg">
          </div>
          <div class="lil-circle" />
          <svg class="blur-circle">
            <filter id="blur">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="13"
              />
            </filter>
            <circle
              cx="70"
              cy="70"
              r="66"
              fill="transparent"
              stroke="white"
              stroke-width="40"
              filter="url(#blur)"
            />
          </svg>
        </div>
        <div class="text1">
          {{ getName() }}載入中...
        </div>
      </div>
    </div>
  </template>

  <router-view />
</template>


<script>
import { config , channelList} from '@/include/config';
import { handylearning } from '@/include/localization/handylearning';
import { podschool } from '@/include/localization/podschool';
// eslint-disable-next-line no-unused-vars
import { h, ref, reactive , watch , inject , onMounted , onUpdated, provide} from 'vue';
// eslint-disable-next-line no-unused-vars
import { oltConsole, oAA , oApiError , oAuth, oUserInfo, oUrl, oSBMeta } from '@/lib/oltModule/initClientVue3';
import moment from 'moment' ;
import { useI18n } from 'vue-i18n';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Modal } from 'bootstrap';
import { oltExternalConfig } from '@/lib/oltExternalConfig';
import { oltWebInfo } from '@/lib/oltWebInfo';
import { oltJavaScriptError } from '@/lib/oltJavaScriptError';

export default {
  name: 'App',
  components: {
    //Loading,
  },
  setup() {
    const swal = inject('$swal') ;
    const { t, locale } = useI18n({
      useScope:'global' // 必需設定 SCOPE 才能覆蓋設定
    }); 
    const router = useRouter();
    const store = useStore();
    const viewTimer = ref(0);
    const isLoading = ref(false);
    const kickGohome = ref(false);    
    const isRouter = ref(false); // 強制等待 onMount 檢查機制完成後，才能執行 router 功能
    const isGoHome = ref(false);
    const routerErrorTimer = ref(0);
    let oWebInfo = new oltWebInfo() ;

    locale.value = 'zh-TW';

    provide(
      'baseApp', {
        guestLogin,
        updateUserInfoStatus,
        logout,
        getUserLoginDefaultData,
        gotoDefaultView,
        getProductImage,
        getLocalizationName,
      }
    );

    function getProductImage() {
      let img = 'assets/img/classicon1.png';

      if(channelList.channelUse != null && channelList.channelUse == 'handylearning'){
        img = 'assets/img/default1.jpg';
      }else if(channelList.channelUse != null && channelList.channelUse == 'podschool'){
        img = 'assets/img/classde.jpg';
      }
      return img;
    }

    function listenUserInfo() {
      // eslint-disable-next-line no-undef
      viewTimer.value = setInterval(userInfoStatus, 3000);
      routerErrorTimer.value = setInterval(showRouterErrorMessage, 1000);
    }

    function updateUserInfoStatus(){
      //console.log('App updateUserInfoStatus');
      //userInfoStatus(false);
    }

    function userInfoStatus(isCache = true) {
      // console.log('userInfoStatus');
      if (oAuth.isLogin(store)) {
        const apiParam = {
          restApiDriver: config.restApiDriver ,// token key from veex store
          // auth
          token:oAuth.getToken(store) ,// token key from veex store 
          // Debug Mode 
          apiGetPageDebug:false , // API getPageRecord 除錯模式
          apiGetRtDebug:false , // API getRt 除錯模式
          // API Filter 
          apiName:'archive_user',
          pageNumber: 1, // int32 
          pageSize: 5, // int 32
          pageSort:'auid' , // string 
          pageSortBy:'asc' , // string 
          customFilter:false, 
          pageFilter:'' , // string
          pageSearch:{
          } , // json format 
          pageGroup:'auid' , // string 
          pageGroupBy:'asc' , // string 
          pageMode: true, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
          // rt 
          rtSet:{
          },
          queueSpeed: 1, // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
        };

        if(!isCache){
          apiParam.pageSearch.isCache = 'N';
        }

        oAA.queueGetAllVue3(apiParam).then(res => {
          if (res != null && res.pageRecord != null && res.pageRecord.data != null && res.pageRecord.data[0] != null) {
            let userInfo = res.pageRecord.data[0];

            oUserInfo.setUserInfo(store, userInfo);
          }
        }).catch((err)=> {
          //console.log('err : ', err);
          //console.log('err response : ', err.response);

          if(err != null && err.response != null && err.response.status != null){
            if(err.response.status == 401 && err.response.data && err.response.data.error_code && err.response.data.error_code == 'SYS0001'){
              if(viewTimer.value){
                clearInterval(viewTimer.value);
              }
              logout();
              isLoading.value = false;
              swal
                .fire({
                  icon: 'error',
                  text: t('messages.'+err.response.data.error_code),
                  confirmButtonText: t('messages.confirm_button'),
                  showConfirmButton: true
                })
                .then((result) => {
                  kickGohome.value = true;
                  $('.modal').modal('hide');
                  $('.modal-backdrop').remove();
                  chkGuestLogin();
                });
            }
            else if(err.response.status == '404'){
              // API 異常，關閉計時器 SBDW5-1380
              if(viewTimer.value){
                clearInterval(viewTimer.value);
              }
            }
          }
          else {
            oApiError.chkError(err,swal);
            // API 異常，關閉計時器 SBDW5-1380
            if(viewTimer.value){
              clearInterval(viewTimer.value);
            }
          }
        });
      }
    }

    function chkLogin() {
      try{
        if (!oAuth.isLogin(store) && !oAuth.isGuestLogin(store)) {
          chkGuestLogin();
        } else {
          stepGetDefaultRecordData();
        }
      }catch(err){
        oltJavaScriptError.exceptionLog('App', 'chkLogin');
      }
    }

    function chkGuestLogin() {
      try{
        if (!oAuth.isGuestLogin(store)) {
          guestLogin();
        } else {
          stepGetDefaultRecordData();
        }
      }catch(err){
        oltJavaScriptError.exceptionLog('App', 'chkGuestLogin');
      }
    }

    function logout(isGuestLogin, goHome) {
      try{
        oAuth.logoutEx(store);
        oAuth.cleanGuest(store);
        isGoHome.value = goHome;
        if(isGuestLogin){
          guestLogin();
        }
      }catch(err){
        oltJavaScriptError.exceptionLog('App', 'logout');
      }
    }

    function guestLogin(){
      try{
        // let uuid = oAuth.getUUId(store);
        let form = {};

        // form.uuid = uuid;
        // console.log("guestLogin");
        const apiParam = {
          restApiDriver: config.restApiDriver ,// token key from veex store 
          // auth
          token:oAuth.getToken(store) ,// token key from veex store 
          // Debug Mode 
          apiPostDebug:false , // API 新增 除錯模式
          apiName:'post_guest_login',
          form:form ,
        };

        //console.log(param); 
        oAA.postRecordVue3(apiParam).then((res)=> {
          oAuth.setGuest(store, res[apiParam.apiName]);
          stepGetDefaultRecordData();
        }).catch((err)=> {
          oApiError.chkError(err,swal);
        });
      }catch(err){
        oltJavaScriptError.exceptionLog('App', 'guestLogin');
      }
    }

    function gotoHome() {
      // 關閉 loading
      isLoading.value = false;
      // 開啟 router 功能
      isRouter.value = true;
      if(kickGohome.value || isGoHome.value){
        kickGohome.value = false;
        isGoHome.value = false;
        if(router != null && router.currentRoute.value.name == 'Home'){
          router.go();
        }else {
          gotoPage('Home');
        }
      }
    }

    function gotoPage(page){
      // 關閉 loading
      isLoading.value = false;
      // 開啟 router 功能
      isRouter.value = true;

      if (router != null && router.options.history.location == '/' && page == '') {
        oUrl.push(router, { path:'Home' });
      }
      else if(router != null && router.currentRoute.value.name != page){
        oUrl.push(router, { path:page });
      }
    }

    /*
    * App onMounted 不再處理初始化功能，統一轉由 Initialize 進行處理
    * Yves modify SBDW5-2072
    */
    const platform = ref('podschool');

    onMounted(async () => {
      if(window.vuplex){
        window.vuplex.addEventListener('message', onMessage);
      }else{
        window.addEventListener('vuplexready', onvuplexready);
      }
      oltConsole.oltVtype = 'prd';
      //console.log('App onMounted');
      if(channelList.channelUse != null){
        platform.value = channelList.channelUse;
      }
      checkCookieUserData();
    });

    function onvuplexready(){
      if(window.vuplex){
        window.vuplex.addEventListener('message', onMessage);
      }
    }
    function onMessage(event){
      let data = event.data;
      if(data){
        let json = JSON.parse(data);
        if(json && json.user){
          oAuth.setSBMetaUser(store, json.user);
        }
        oSBMeta.setSBMeta(store, json.data);
      }
    }
    function checkKeepLogin(userData){
      try{
        const apiParam = {
          restApiDriver: config.restApiDriver ,// token key from veex store 
          // auth
          token:userData.tokens.token ,// token key from veex store 
          // Debug Mode 
          apiGetPageDebug:false , // API getPageRecord 除錯模式
          apiGetRtDebug:false , // API getRt 除錯模式
          // API Filter 
          apiName:'archive_user',
          pageNumber: 1, // int32 
          pageSize: 5, // int 32
          pageSort:'auid' , // string 
          pageSortBy:'asc' , // string 
          customFilter:false, 
          pageFilter:'' , // string
          pageSearch:{
          } , // json format 
          pageGroup:'auid' , // string 
          pageGroupBy:'asc' , // string 
          pageMode: true, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
          // rt 
          rtSet:{
          },
          queueSpeed: 1, // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
        };

        oAA.queueGetAllVue3(apiParam).then(res => {
          // console.log("[checkKeepLogin] res ==> ", res);
          oAuth.setSessionStorage(store);
          stepSetUserInfo();
        }).catch((err)=> {          
          oltConsole.log(err);
          if(err != null && err.response != null && err.response.status != null){
            if(err.response.status == '401' && err.response.data && err.response.data.error_code && err.response.data.error_code == 'SYS0001'){
              // Token 失效，強制刪除本地端資料
              logout();
              stepSetUserInfo();
            }
          }
        });
      }catch(err){
        oltJavaScriptError.exceptionLog('App', 'checkKeepLogin');
      }
    }
    
    /*
    * 檢查本地端是否有上次登入資訊
    * 1. 若本地端有資料，就先呼叫 API，檢查 token 是否還可使用
    * 2. 若 token 還可使用，就將資訊塞入 session，視為自動登入
    * 3. 若 token 不可使用，就清除本地端資料
    */
    function checkCookieUserData(){
      try{
        // 取得使用者資訊
        let userData = oAuth.getUser(store);
        // console.log("[checkCookieUserData] UserData ==> ", userData);
        if(userData != null && userData.tokens != null && userData.tokens.token != null){
          // 檢查是否被踢
          checkKeepLogin(userData);
        }else{
          // 取得Cookie裡的User
          userData = oAuth.getCookieUserData(store);
          // console.log("[checkCookieUserData] Cookie UserData ==> ", userData);
          if(userData != null && userData.tokens != null && userData.tokens.token != null){
            // 檢查是否被踢
            checkKeepLogin(userData);
          }else{
            stepSetUserInfo();
          }
        }
      }catch(err){
        oltJavaScriptError.exceptionLog('App', 'checkCookieUserData');
      }
    }

    /*
    * 設定使用者資訊
    * 1. 開啟背景每秒更新使用者資訊計時器
    * 2. 檢查當前 session 是否已登入
    */
    function stepSetUserInfo(){
      listenUserInfo();
      // chkLogin();
    }

    /*
    * 取得預設資料
    * 1. 從 API 取得
    * 2. 從參數檔取得
    * 3. ....
    * 
    * End. 轉跳至首頁
    */
    function stepGetDefaultRecordData(){
      // console.log('stepGetDefaultRecordData');
      //console.log('store : ', store);

      // get default data
      getDefaultRecordData();
    }    

    function getDefaultRecordData() {

      // if(!oAuth.isLogin(store)){
      //   // 若是未登入就不執行
      //   return;
      // }

      const apiParam = {
        restApiDriver: config.restApiDriver ,// token key from veex store 
        // auth
        token:oAuth.getToken(store) ,// token key from veex store 
        // Debug Mode 
        apiGetPageDebug:false , // API getPageRecord 除錯模式
        apiGetRtDebug:false , // API getRt 除錯模式
        // API Filter 
        apiName:'server_info_config',
        pageNumber: 1, // int32 
        pageSize: 5, // int 32
        pageSort:'seq' , // string 
        pageSortBy:'asc' , // string 
        customFilter:false, 
        pageFilter:'' , // string
        pageSearch:{
        } , // json format 
        pageGroup:'' , // string 
        pageGroupBy:'' , // string 
        pageMode: true, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
        // rt 
        rtSet:{
          'ProductGenre':{
            type:'all' ,
            apiName: 'product_genre',
            pageSearch:{switch:[true]}, // { uid:[1,2,3,4,5] , seq:[10,100] }
            pageSort:'pgid' , // string
            pageSortBy:'asc' , // string
          } ,
        },
        queueSpeed: 1, // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
      };

      if(oAuth.isLogin(store)){ // 若是登入，多增加 rt
        let user = oAuth.getUser(store);

        apiParam.rtSet.RoleViewRt = {
          type:'all' ,
          apiName: 'role_view_rt_join_by_uid',
          pageSearch:{switch:[true], uid:[user.uid]},
          pageSort:'rvrid' ,
          pageSortBy:'asc' ,
        };
      }
      else{
        apiParam.rtSet.RoleViewRt = {
          type:'all' ,
          apiName: 'role_view_rt_join',
          pageSearch:{switch:[true], rid_code:['guest']},
          pageSort:'rvrid' ,
          pageSortBy:'asc' ,
        };
      }
      //console.log('apiParam : ', apiParam);

      oAA.queueGetAllVue3(apiParam).then(res => {
        //console.log('res : ', res);
        let temp = null;

        if (res != null && res.pageRecord != null && res.pageRecord != null) {
          oUserInfo.setServerInfoConfig(store, res.pageRecord);
          if(res.rtAllRecord != null){
            if(res.rtAllRecord.ProductGenre != null && res.rtAllRecord.ProductGenre.length > 0){
              for (let i = 0; i < res.rtAllRecord.ProductGenre.length ;i++){
                temp = res.rtAllRecord.ProductGenre[i];
                if(temp != null){
                  if(temp.code != null && temp.name != null){
                    if(temp.code == 'course'){
                      oUserInfo.setCourseName(store, temp.name);
                    }
                    if(temp.code == 'exercise'){
                      if(channelList.channelUse == 'handylearning'){
                        oUserInfo.setExerciseName(store, 'e評量');
                      }else{
                        oUserInfo.setExerciseName(store, temp.name);
                      }
                    }
                    if(temp.code == 'ebook'){
                      oUserInfo.setEbookName(store, temp.name);
                    }
                  }
                }
              }
            }
            if(res.rtAllRecord.RoleViewRt != null){
              // 畫面轉跳權限檢查機制
              oUserInfo.setRouterViewPermission(store, res.rtAllRecord.RoleViewRt);
            }
          }
        }

        gotoHome();
      }).catch((err)=> {
        oApiError.chkError(err,swal); 
      });
    }

    /*
    * 專門用來顯示 Router 需要顯示的錯誤訊息
    * 因為 Router 本身不負責畫面顯示的部分，所以無法掛載顯示元件，只好改成存放在 vuex，再開一個固定的計時器進行檢查並顯示
    * Yves SBDW5-1991
    */
    function showRouterErrorMessage(){
      let routerErrorMessage = oUserInfo.getRouterErrorMessage(store);

      if(routerErrorMessage != null && routerErrorMessage != ''){
        oUserInfo.clearRouterErrorMessage(store);
        swal.fire({
          icon:'warning',
          text:routerErrorMessage,
          confirmButtonText:t('messages.confirm_button'),
          showConfirmButton: true,
        }).then( result => {
          gotoDefaultView();
        });
      }
    }

    function gotoDefaultView(){
      let isLogin = oAuth.isLogin(store);
      let isGeneral = oWebInfo.isWebTypeGeneral(store);
      let isLite = oWebInfo.isWebTypeLite(store);

      if(isGeneral == true && isLogin == false){
        // 一般版，並且未登入
        gotoPage('Home');
      }
      else if(isGeneral == true){
        let user = oAuth.getUser(store);

        if(user != null){
          if(user.is_main_account != null && user.is_main_account == 0){
            // 子帳號
            gotoPage('MyLearn');
          }
          else{
            // 主帳號
            gotoPage('Home');
          }
        }
      }
      else if(isLite == true && isLogin == false){
        // Lite 版，並且未登入，就強制轉跳到登入畫面
        gotoPage('LiteLogin');
      }
      else if(isLite == true){
        gotoPage('Home');
      }

    }

    /*
    * 專門提供給登入後，取得使用者預設資訊
    *
    */
    function getUserLoginDefaultData(){
      //console.log('getUserLoginDefaultData');
      let user = oAuth.getUser(store);

      const apiParam = {
        restApiDriver: config.restApiDriver ,// token key from veex store 
        // auth
        token:oAuth.getToken(store) ,// token key from veex store 
        // Debug Mode 
        apiGetPageDebug:false , // API getPageRecord 除錯模式
        apiGetRtDebug:false , // API getRt 除錯模式
        // API Filter 
        apiName:'role_view_rt_join_by_uid',
        pageNumber: 1, // int32 
        pageSize: 999, // int 32
        pageSort:'seq' , // string 
        pageSortBy:'asc' , // string 
        customFilter:false, 
        pageFilter:'' , // string
        pageSearch:{
          uid:[user.uid],
          switch:[true], 
        } , // json format 
        pageGroup:'' , // string 
        pageGroupBy:'' , // string 
        pageMode: true, // 是否打開換頁邏輯，若設定 fale 則預設只抓 1000 筆，參考 FunsController.php 。
        // rt 
        rtSet:{
        },
        queueSpeed: 1, // 異步 IO queue 的 順序執行間隔時間 1/1000 秒
      };

      //console.log('apiParam : ', apiParam);

      oAA.queueGetAllVue3(apiParam).then(res => {
        //console.log('res : ', res);

        if (res != null && res.pageRecord != null && res.pageRecord != null && res.pageRecord.data != null) {
          // 畫面轉跳權限檢查機制
          oUserInfo.setRouterViewPermission(store, res.pageRecord.data);
        }

      }).catch((err)=> {
        oApiError.chkError(err,swal); 
      });
    }

    function getName(){
      console.log(channelList.channelUse);
      if(channelList.channelUse == 'handylearning'){
        return '掌中學院';
      }
      return '口袋學校';
    }
    
    function getLocalizationName(item){
      let name = '';

      if(channelList.channelUse == 'handylearning'){
        name = handylearning[item];
      }else{
        name = podschool[item];
      }
      return name;
    }

    return {
      moment,
      t,
      locale,      
      token:null,
      kickGohome,
      isRouter,
      isGoHome,
      isLoading,
      getName,
      onvuplexready,
      onMessage,
    };
  },
};
</script>

<style>
  /*解決iOS滑動*/
  .{
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    overflow-x: scroll;
  }
  .swal2-styled.swal2-confirm:focus {
    box-shadow: none;
  }
</style>
