/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  // 重新 Reload 瀏覽器時 會從 sessionStorage 放進 state.
  sbmeta: oltEncrypt.storeDecodeObject(sessionStorage.getItem('sbmeta'))
};
/*
mutations 必須是同步執行，而 actions 則可以異步執行。
*/
export const actions = {
  setSBMeta({ commit }, sbmeta) {
    commit('setSBMeta', sbmeta);
  },
  cleanSBMeta({ commit }) {
    commit('cleanSBMeta');
  },
};

export const mutations = {
  setSBMeta(state, sbmeta) {
    sessionStorage.setItem('sbmeta', oltEncrypt.storeEncodeObject(sbmeta));
    state.sbmeta = sbmeta;
  },
  cleanSBMeta(state){
    sessionStorage.removeItem('sbmeta');
    state.sbmeta = null;
  }
};

export const getters = {
  isSBMeta(state) {
    if (state.sbmeta) {
      return true;
    }
    return false;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
