/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
import { oAA , oApiError , oAuth, oUserInfo, oUrl } from '@/lib/oltModule/initClientVue3';
import { config } from '@/include/config';
import { useStore } from 'vuex';
import InApp from 'detect-inapp';

// init oltConsole
export class oltJavaScriptErrorModule {
  constructor() {
    //const oltConsole = new oce() ;
    oltConsole.log('oltJavaScriptError!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this

  exceptionLog(className, functionName, err, swal, t){
    oltConsole.log(`[exceptionLog] className ==> ${className}, functionName ==> ${functionName}`);
    oltConsole.log("[exceptionLog] error ==> ", err);
    
    this.store = useStore();

    let device = ''; 
    const useragent = navigator.userAgent || navigator.vendor || window.opera;
    const inapp = new InApp(useragent);
    if(inapp.isMobile){
      device = 'mobile';
    }else if(inapp.isDesktop){
      device = 'desktop';
    }else if(inapp.browser){
      device = 'browser';
    }

    if(this.store && this.store.state){
      if(this.store.state.auth && this.store.state.auth.user && this.store.state.auth.user.uid){
        this.uid = this.store.state.auth.user.uid;
      }else if(this.store.state.guestAuth && this.store.state.guestAuth.guest && this.store.state.guestAuth.guest.uid){
        this.uid = this.store.state.guestAuth.guest.uid;
      }else{
        this.uid = null;
      }

      if(this.store.state.auth && this.store.state.auth.user && this.store.state.auth.user.tokens && this.store.state.auth.user.tokens.token){
        this.tokens = this.store.state.auth.user.tokens.token;
      }else if(this.store.state.guestAuth && this.store.state.guestAuth.guest && this.store.state.guestAuth.guest.tokens && this.store.state.guestAuth.guest.tokens.token){
        this.tokens = this.store.state.guestAuth.guest.tokens.token;
      }else{
        this.tokens = null;
      }
    }else{
      this.uid = null;
      this.tokens = null;
    }

    if(this.tokens != null){
      const apiParam = {
        restApiDriver: config.restApiDriver ,// token key from veex store 
        // auth
        token: this.tokens ,
        // Debug Mode 
        apiPostDebug:false , // API 新增 除錯模式
        apiName:'exception_client_log',
        form:{
          uid:this.uid,
          device:device,
          class_name:className,
          function_name:functionName,
          error_message:err.message,
          seq:10,
          switch: 1,
        } ,
      };     
      // console.log("apiParam form ==> ", apiParam.form);       
      let resObj = oAA.postRecordVue3(apiParam) ; 
      resObj.then((res)=> {
        // console.log(res);
      }).catch((err)=> {
        console.log(err.message);
        // oltConsole.log(err.response.data.error);
      });
    }
  }
}

// eslint-disable-next-line new-cap
const oltJavaScriptError = new oltJavaScriptErrorModule();

export { oltJavaScriptError };
