// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
import { oltEncrypt } from '@/lib/oltEncrypt';
import { oltCookie } from '@/lib/oltCookie';


// init oltConsole
export class auth {
  constructor() {
    oltConsole.log('Init auth!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this
  chkSignup(router, store, swal, t) {
    if (!store.getters.isSignup) {
      swal.fire({
        icon: 'warning',
        html: t('messages.chk_signup', { ex: '' }),
        confirmButtonText: t('messages.confirm_button'),
        showConfirmButton: true,
        timer: 2000
      });
      router.push('Signup');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  alreadySignup(router, store, swal, t) {
    if (store.getters.isSignup) {
      swal.fire({
        icon: 'warning',
        html: t('messages.already_signup', { ex: '' }),
        confirmButtonText: t('messages.confirm_button'),
        showConfirmButton: true,
        timer: 2000
      });
      router.push('PrivacyPolicy');
    }
  }

  // eslint-disable-next-line class-methods-use-this
  alreadyLogin(router, store, swal, t) {
    if (store.getters.isLogin) {
      swal.fire({
        icon: 'warning',
        html: t('messages.already_login', { ex: '' }),
        confirmButtonText: t('messages.confirm_button'),
        showConfirmButton: true,
        timer: 2000
      });
      router.go(-1);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  chkLogin(router, store, swal, t) {
    if (!store.getters.isLogin) {
      swal.fire({
        icon: 'error',
        html: t('messages.chk_login', { ex: '才可以使用本功能' }),
        confirmButtonText: t('messages.confirm_button'),
        showConfirmButton: true,
        timer: 3000
      });
      router.push('/LoginSelect');
    } else {
      // 認證成功 回傳 Token KEY
      return store.state.auth.user.tokens.token;
    }
  }

  // 判斷狀態是否為登入
  // eslint-disable-next-line class-methods-use-this
  isLogin(store) {
    if (store.getters.isLogin) {
      return true;
    }
    return false;
  }

  // 將 登入資訊 記到 Store 中
  // eslint-disable-next-line class-methods-use-this
  pushUserLogin(router, store, user) {
    store.commit('loginSuccess', user);

    let isGod = false;
    let isStudent = false;

    if (user && user.user_type && user.user_type[0] && user.user_type[0].code == 'admin'){
      isGod = true;
    }

    if(user && user.is_main_account == 0){
      isStudent = true;
    }

    if (store.getters.isLocationUrl) {
      let locationUrl = '';
      let urlQuery = '';
      let urlParams = '';

      if(store.state.url.locationUrl){
        locationUrl = store.state.url.locationUrl;
        urlQuery = store.state.url.urlQuery ? store.state.url.urlQuery : '';
        urlParams = store.state.url.urlParams ? store.state.url.urlParams : '';
      }

      store.dispatch('cleanLocationUrl'); // 清空 locationUrl
      store.dispatch('cleanUrlQuery'); // 清空 locationUrl
      store.dispatch('cleanUrlParams'); // 清空 locationUrl

      if (locationUrl && locationUrl != '') {
        if(router.currentRoute.value.path == locationUrl || router.currentRoute.value.name == locationUrl){
          router.go();
        }
        else {
          router.push({
            path: locationUrl,
            query: urlQuery,
            params: urlParams
          });
        }
      }
      else {
        router.go();
      }
    } 
    else if(isStudent){
      if(router.currentRoute.value.name == 'MyLearn'){
        router.go();
      }else{
        router.push('MyLearn');
      }
    }
    else if(isGod){
      if(router.currentRoute.value.name == 'ShoppingCart'){
        router.push('Home');
      }
      else {
        router.go();
      }
    }
    else if(router.currentRoute.value.name == 'ShopGroupBuying'){
      router.go(0);
    }
    else {
      router.go();
    }
  }

  // 將 登入資訊 記到 Store 中
  // eslint-disable-next-line class-methods-use-this
  pushUser(router, store, user) {
    store.commit('loginSuccess', user);
  }

  // eslint-disable-next-line class-methods-use-this
  logout(router, store, swal, t) {
    swal
      .fire({
        icon: 'warning',
        text: t('messages.logout_confirm', { ex: '' }),
        confirmButtonText: t('messages.confirm_button'),
        cancelButtonText: t('messages.cancel_button'),
        showConfirmButton: true,
        showCancelButton: true
      })
      .then((result) => {
        if (result.isConfirmed) {
          store.commit('logout');
          store.commit('cleanUserInfo');
          store.commit('clearShoppingCart');
          store.commit('clearPayment');
          store.commit('clearKnowledgeVideo');
          store.commit('cleanRouterHistory');
          router.push('Home');
        }
      });
  }

  // eslint-disable-next-line class-methods-use-this
  logoutEx(store) {
    store.commit('logout');
    store.commit('cleanUserInfo');
    store.commit('clearShoppingCart');
    store.commit('clearPayment');
    store.commit('clearKnowledgeVideo');
    store.commit('cleanRouterHistory');
  }

  // eslint-disable-next-line class-methods-use-this
  logoutExHome(router, store) {
    store.commit('logout');
    store.commit('cleanUserInfo');
    store.commit('clearShoppingCart');
    store.commit('clearPayment');
    store.commit('clearKnowledgeVideo');
    store.commit('cleanRouterHistory');
    router.push('Home');
  }

  // eslint-disable-next-line class-methods-use-this
  isGuestLogin(store) {
    if (store.getters.isGuest) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  setGuest(store, Obj) {
    store.dispatch('setGuest', Obj);
  }

  // eslint-disable-next-line class-methods-use-this
  cleanGuest(store) {
    store.dispatch('cleanGuest');
  }

  // eslint-disable-next-line class-methods-use-this
  getUUId(store) {
    let uuid = '';

    if (!store.getters.isUUId) {
      uuid = Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
      store.dispatch('setUUId', uuid);
    } else {
      uuid = store.state.guestAuth.uuid;
    }
    return uuid;
  }

  // eslint-disable-next-line class-methods-use-this
  cleanUUId(store) {
    store.dispatch('cleanUUId');
  }

  // eslint-disable-next-line class-methods-use-this
  getToken(store) {
    if (store.getters.isLogin) {
      if(store.state.auth.user.tokens != null && store.state.auth.user.tokens.token != null){
        return store.state.auth.user.tokens.token;
      }
    } else if (store.getters.isGuest) {
      if(store.state.guestAuth.guest.tokens != null && store.state.guestAuth.guest.tokens.token != null){
        return store.state.guestAuth.guest.tokens.token;
      }
    }
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  setSessionStorage(store) {
    let user = null;
    let signup = null;
    let isAccept = oltCookie.getAcceptCookie();
    if(isAccept && isAccept === 'accept'){
      user = oltCookie.getCookie('user');
      signup = oltCookie.getCookie('signup');
    }
    if(user && !sessionStorage.getItem('user')){
      sessionStorage.setItem('user', user);
      store.commit('updateStateUser', oltEncrypt.storeDecodeObject(user));
    }
    if(signup && !sessionStorage.getItem('signup')){      
      sessionStorage.setItem('signup', signup);
      store.commit('updateStateSignup', oltEncrypt.storeDecodeText(signup));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getCookieUserData(store) {
    let user = null;
    let isAccept = oltCookie.getAcceptCookie();
    // console.log(`[getCookieUserData] isAccept ==> ${isAccept}`);
    if(isAccept && isAccept === 'accept'){
      user = oltCookie.getCookie('user');
    }
    if(user && user != ''){
      // console.log('cookie user : ',oltEncrypt.storeDecodeObject(user));
      return oltEncrypt.storeDecodeObject(user);
    }
    return null;
  }

  // eslint-disable-next-line class-methods-use-this
  setOpenIdFacebook(store) {
    store.dispatch('setOpenIdFacebook');
  }
  // eslint-disable-next-line class-methods-use-this
  setOpenIdGoogle(store) {
    store.dispatch('setOpenIdGoogle');
  }
  // eslint-disable-next-line class-methods-use-this
  setOpenIdApple(store) {
    store.dispatch('setOpenIdApple');
  }
  // eslint-disable-next-line class-methods-use-this
  setOpenIdSSO(store) {
    store.dispatch('setOpenIdSSO');
  }
  // eslint-disable-next-line class-methods-use-this
  getOpenid(store) {
    let openid = null;

    if (store.getters.isOpenid) {
      openid = store.state.openid.openid;
    }
    return openid;
  }
  // eslint-disable-next-line class-methods-use-this
  chkOpenid(router, store, swal, t) {
    if (!store.getters.isOpenid) {
      swal.fire({
        icon: 'warning',
        html: t('messages.chk_openid', { ex: '' }),
        confirmButtonText: t('messages.confirm_button'),
        showConfirmButton: true,
        timer: 2000
      });
      router.push('Signup');
    }
  }
  // eslint-disable-next-line class-methods-use-this
  getLoginTypeId(store) {
    let ltid = '';

    if (store.getters.isLtid) {
      ltid = store.state.openid.ltid;
    }
    return ltid;
  }
  // eslint-disable-next-line class-methods-use-this
  getOpenidStr(store) {
    let str = '';

    if (store.getters.isOpenid && store.getters.isLtid) {
      let ltid = this.getLoginTypeId(store);

      if (ltid == 'facebook') {
        str = store.state.openid.openid.id;
      } else if (ltid == 'google') {
        str = store.state.openid.openid.id;
      } else if (ltid == 'appleid') {
        str = store.state.openid.openid.id;
      } else if (ltid == 'sso') {
        str = store.state.openid.openid.id;
      }
    }
    return str;
  }

  // eslint-disable-next-line class-methods-use-this
  setSBMetaUser(store, user) {
    store.dispatch('setSBMetaUser', user);
  }
  // eslint-disable-next-line class-methods-use-this
  getSBMetaUser(store){
    if(store.getters.isLogin){
      return store.state.auth.user;
    }
    return false ; 
  }


  getUserTypeCode(store){
    if (store.getters.isLogin) {
      if(store.state.auth.user.user_type != null && store.state.auth.user.user_type[0] != null && store.state.auth.user.user_type[0].code != null){
        return store.state.auth.user.user_type[0].code;
      }
    } else if (store.getters.isGuest) {
      return 'guest';
    }
    return '';
  }

  getUser(store){
    if (store.getters.isLogin && store.state.auth.user != null){
      return store.state.auth.user;
    }
    return '';
  }

  
}
