/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { oltConsole } from '@/lib/oltModule/oltConsole';
class oltEncryptModule {
  constructor() {
    oltConsole.log('oltEncrypt constructor');
  }
  test() {
    oltConsole.log('oltEncrypt test');
  }

  storeEncodeText(value) {
    if(!value || value == ''){ return ''; }
    let str = '';

    try {
      str = btoa(encodeURIComponent(value));
    } catch (e) {
      oltConsole.log('oltEncryptModule storeEncodeText fail. value : ', value);
    }
    return str;
  }

  storeDecodeText(value) {
    if(!value || value == ''){ return ''; }
    let str = '';

    try {
      str = decodeURIComponent(atob(value));
    } catch (e) {
      oltConsole.log('oltEncryptModule storeDecodeText fail. value : ', value);
    }
    return str;
  }

  storeEncodeObject(value) {
    if(!value || value == ''){ return ''; }
    let str = '';

    try {
      str = this.storeEncodeText(JSON.stringify(value));
    } catch (e) {
      oltConsole.log(
        'oltEncryptModule storeEncodeObject fail. value : ',
        value
      );
    }
    return str;
  }

  storeDecodeObject(value) {
    if(!value || value == ''){ return null; }
    let str = null;

    try {
      str = JSON.parse(this.storeDecodeText(value));
    } catch (e) {
      oltConsole.log(
        'oltEncryptModule storeEncodeObject fail. value : ',
        value
      );
    }
    return str;
  }

  encodeConfigObject(value) {
    return this.storeEncodeObject(value);
  }

  decodeConfigObject(value) {
    return this.storeDecodeObject(value);
  }

  /**
   * 將網址整串轉成一組加密後參數
   * Yves add SBDW5-2253
   * @param {*} obj 
   * @returns 
   */
  webUrlEncode(obj){
    let res = '';

    try {
      let encodeStr = '';

      encodeStr = JSON.stringify(obj);
      encodeStr = encodeURI(encodeStr);
      encodeStr = btoa(encodeStr);
      res = encodeStr;
    } catch (e) {
      oltConsole.log( 'oltEncryptModule webUrlEncode fail. value : ', obj );
    }

    return res;
  }

  /**
   * 將加密參數進行解密
   * Yves add SBDW5-2253
   * @param {*} string 
   * @returns 
   */
  webUrlDecode(string){
    let res = null;

    try {
      let decodeStr = null;

      decodeStr = atob(string);
      decodeStr = decodeURI(decodeStr);
      decodeStr = JSON.parse(decodeStr);
      res = decodeStr;
    } catch (e) {
      oltConsole.log( 'oltEncryptModule webUrlDecode fail. value : ', string );
    }

    return res;
  }
}

// eslint-disable-next-line new-cap
const oltEncrypt = new oltEncryptModule();

export { oltEncrypt };
