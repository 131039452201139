/* eslint-disable no-shadow */
import { oltEncrypt } from '@/lib/oltEncrypt';
export const state = {
  // 重新 Reload 瀏覽器時 會從 sessionStorage 放進 state.
  userInfo: oltEncrypt.storeDecodeObject(sessionStorage.getItem('userInfo')),
  timer: {},
  shoppingCart: oltEncrypt.storeDecodeObject(sessionStorage.getItem('shoppingCart')),
  openid: oltEncrypt.storeDecodeObject(sessionStorage.getItem('openid')),
  payment: oltEncrypt.storeDecodeObject(sessionStorage.getItem('payment')),  
  knowledgeVideo: oltEncrypt.storeDecodeObject(sessionStorage.getItem('knowledgeVideo')),
  tempID: oltEncrypt.storeDecodeObject(sessionStorage.getItem('tempID')),
  homeProfileCheck: oltEncrypt.storeDecodeObject(sessionStorage.getItem('homeProfileCheck')),
  paymentChose: oltEncrypt.storeDecodeObject(sessionStorage.getItem('paymentChose')),
  epid: oltEncrypt.storeDecodeText(sessionStorage.getItem('epid')),
  courseName: oltEncrypt.storeDecodeText(sessionStorage.getItem('courseName')),
  exerciseName: oltEncrypt.storeDecodeText(sessionStorage.getItem('exerciseName')),
  ebookName: oltEncrypt.storeDecodeText(sessionStorage.getItem('ebookName')),
  serverInfoConfig: oltEncrypt.storeDecodeObject(sessionStorage.getItem('serverInfoConfig')),
  routerViewPermission: oltEncrypt.storeDecodeObject(sessionStorage.getItem('routerViewPermission')),
  routerErrorMessage: oltEncrypt.storeDecodeText(sessionStorage.getItem('routerErrorMessage')),
  tempVoucher: oltEncrypt.storeDecodeText(sessionStorage.getItem('tempVoucher')),
  currentVideo: oltEncrypt.storeDecodeObject(sessionStorage.getItem('currentVideo')),
};

export const actions = {
  setUserInfo({ commit }, userInfo) {
    commit('setUserInfo', userInfo);
  },
  cleanUserInfo({ commit }) {
    commit('cleanUserInfo');
  },
  setTimerHeaderViewId({ commit }, id) {
    commit('setTimerHeaderViewId', id);
  },
  // 購物車
  setShoppingCart({ commit }, array) {
    commit('setShoppingCart', array);
  },
  setTempShoppingCart({ commit }, array) {
    commit('setTempShoppingCart', array);
  },
  clearShoppingCart({ commit }) {
    commit('clearShoppingCart');
  },
  deleteOneShoppingCart({ commit }, id) {
    commit('deleteOneShoppingCart', id);
  },
  setOneShoppingCart({ commit }, object) {
    commit('setOneShoppingCart', object);
  },
  deletePositionShoppingCart({ commit }, position) {
    commit('deletePositionShoppingCart', position);
  },
  
  // 第三方登入
  setOpenid({ commit }, object) {
    commit('setOpenid', object);
  },
  clearOpenid({ commit }) {
    commit('clearOpenid');
  },
  // 金流
  setPayment({ commit }, object) {
    commit('setPayment', object);
  },
  clearPayment({ commit }) {
    commit('clearPayment');
  },
  // 知識點
  setOneKnowledgeVideo({ commit }, object) {
    commit('setOneKnowledgeVideo', object);
  },
  clearKnowledgeVideo({ commit }) {
    commit('clearKnowledgeVideo');
  },
  // 個人 tempID
  setTempID({ commit }, object) {
    commit('setTempID', object);
  },
  clearTempID({ commit }) {
    commit('clearTempID');
  },
  // 首頁 - 填寫個人資訊視窗
  setHomeProfileCheck({ commit }, object) {
    commit('setHomeProfileCheck', object);
  },
  clearHomeProfileCheck({ commit }) {
    commit('clearHomeProfileCheck');
  },
  // 付款方式選擇
  setPaymentChose({ commit }, object) {
    commit('setPaymentChose', object);
  },
  clearPaymentChose({ commit }) {
    commit('clearPaymentChose');
  },
  // 考卷ID
  setEpid({ commit }, id) {
    commit('setEpid', id);
  },
  // 課程名稱
  setCourseName({ commit }, name) {
    commit('setCourseName', name);
  },
  // 電子書名稱
  setExerciseName({ commit }, name) {
    commit('setExerciseName', name);
  },
  // 題庫名稱
  setEbookName({ commit }, name) {
    commit('setEbookName', name);
  },
  // 伺服器參數
  setServerInfoConfig({ commit }, object) {
    commit('setServerInfoConfig', object);
  },
  clearServerInfoConfig({ commit }) {
    commit('clearServerInfoConfig');
  },
  // 訪客專用，購物車數量+1
  plusOneShoppingCartNum({ commit }) {
    console.log('plusOneShoppingCartNum2');
    commit('plusOneShoppingCartNum');
  },
  // 訪客專用，購物車數量-1
  minusOneShoppingCartNum({ commit }) {
    commit('minusOneShoppingCartNum');
  },
  
  // 設定畫面轉跳權限資料
  setRouterViewPermission({ commit }, object) {
    commit('setRouterViewPermission', object);
  },
  // 清空畫面轉跳權限資料
  clearRouterViewPermission({ commit }) {
    commit('clearRouterViewPermission');
  },

  // 設定 router 錯誤訊息
  setRouterErrorMessage({ commit }, object) {
    commit('setRouterErrorMessage', object);
  },
  // 清空 router 錯誤訊息
  clearRouterErrorMessage({ commit }) {
    commit('clearRouterErrorMessage');
  },

  // 設定暫存活動序號
  setTempVoucher({ commit }, value) {
    commit('setTempVoucher', value);
  },
  // 清空暫存活動序號
  clearTempVoucher({ commit }) {
    commit('clearTempVoucher');
  },
  // 設定目前影片資訊
  setCurrentVideo({ commit }, value) {
    commit('setCurrentVideo', value);
  },
  // 清空目前影片資訊
  clearCurrentVideo({ commit }) {
    commit('clearCurrentVideo');
  },
};

/*
Mutations其實就是包含著一堆函數的物件，每一個mutation就是一個函數，負責接收actions並更改state的資料，其中有幾個注意事項。

mutation必定是同步函數，沒有例外
mutation是vuex中唯一可以改動state的方法
也就是說在vuex中，如果你要改變state狀態，你一定要經過以下的流程

發出一個action(dispatch an action)
接收到action後，執行對應的mutation (commit a mutation)
透過mutation更改state
*/
export const mutations = {
  setUserInfo(state, userInfo) {
    sessionStorage.setItem('userInfo', oltEncrypt.storeEncodeObject(userInfo));
    state.userInfo = userInfo;
  },
  cleanUserInfo(state) {
    sessionStorage.removeItem('userInfo');
    state.userInfo = null;
  },
  setTimerHeaderViewId(state, id) {
    state.timer.headerViewId = id;
  },
  // 購物車
  setShoppingCart(state, obj) {
    sessionStorage.setItem('shoppingCart', oltEncrypt.storeEncodeObject(obj));
    state.shoppingCart = obj;
  },
  clearShoppingCart(state) {
    sessionStorage.removeItem('shoppingCart');
    state.shoppingCart = null;
  },
  deleteOneShoppingCart(state, id) {
    if (state.shoppingCart != null){
      let tempArr = [];

      for (let i = 0; i < state.shoppingCart.length; i++){
        if (state.shoppingCart[i]['plid'] == id){
          continue;
        }
        tempArr.push(state.shoppingCart[i]);
      }

      sessionStorage.setItem('shoppingCart', oltEncrypt.storeEncodeObject(tempArr));
      state.shoppingCart = tempArr;
    }
  },
  setOneShoppingCart(state, obj){
    if (state.shoppingCart == null){
      state.shoppingCart = [];
    }
    let tempArr = [];

    tempArr.push(obj);
    if (state.shoppingCart.length > 0){
      for (let i = 0; i < state.shoppingCart.length; i++){
        tempArr.push(state.shoppingCart[i]);
      }
    }
    state.shoppingCart = tempArr;
    sessionStorage.setItem('shoppingCart', oltEncrypt.storeEncodeObject(state.shoppingCart));
  },
  deletePositionShoppingCart(state, position){
    let tempArr = [];

    for (let i = 0; i < state.shoppingCart.length; i++){
      if (i == position){
        continue;
      }
      tempArr.push(state.shoppingCart[i]);
    }
    sessionStorage.setItem('shoppingCart', oltEncrypt.storeEncodeObject(tempArr));
    state.shoppingCart = tempArr;
  },
  // 第三方登入
  setOpenid(state, obj){
    sessionStorage.setItem('openid', oltEncrypt.storeEncodeObject(obj));
    state.openid = obj;
  },
  clearOpenid(state){
    sessionStorage.removeItem('openid');
    state.openid = null;
  },
  // 金流
  setPayment(state, obj){
    sessionStorage.setItem('payment', oltEncrypt.storeEncodeObject(obj));
    state.payment = obj;
  },
  clearPayment(state){
    sessionStorage.removeItem('payment');
    state.payment = null;
  },
  // 知識點
  setOneKnowledgeVideo(state, obj){
    if (state.knowledgeVideo == null){
      state.knowledgeVideo = [];
    }
    state.knowledgeVideo.push(obj);
    sessionStorage.setItem('knowledgeVideo', oltEncrypt.storeEncodeObject(state.knowledgeVideo));
  },
  clearKnowledgeVideo(state){
    sessionStorage.removeItem('knowledgeVideo');
    state.knowledgeVideo = null;
  },

  // 個人 tempID
  setTempID(state, obj){
    sessionStorage.setItem('tempID', oltEncrypt.storeEncodeObject(obj));
    state.tempID = obj;
  },
  clearTempID(state){
    sessionStorage.removeItem('tempID');
    state.tempID = null;
  },

  // 首頁 - 填寫個人資訊視窗
  setHomeProfileCheck(state, obj){
    sessionStorage.setItem('homeProfileCheck', oltEncrypt.storeEncodeObject(obj));
    state.homeProfileCheck = obj;
  },
  clearHomeProfileCheck(state){
    sessionStorage.removeItem('homeProfileCheck');
    state.homeProfileCheck = null;
  },
  // 付款方式選擇
  setPaymentChose(state, obj) {
    sessionStorage.setItem('paymentChose', oltEncrypt.storeEncodeObject(obj));
    state.paymentChose = obj;
  },
  clearPaymentChose(state){
    sessionStorage.removeItem('paymentChose');
    state.homeProfileCheck = null;
  },
  // 考卷 ID
  setEpid(state, id) {
    sessionStorage.setItem('epid', oltEncrypt.storeEncodeText(id));
    state.epid = id;
  },
  // 課程名稱
  setCourseName(state, name) {
    sessionStorage.setItem('courseName', oltEncrypt.storeEncodeText(name));
    state.courseName = name;
  },
  // 題庫名稱
  setExerciseName(state, name) {
    sessionStorage.setItem('exerciseName', oltEncrypt.storeEncodeText(name));
    state.exerciseName = name;
  },
  // 電子書名稱
  setEbookName(state, name) {
    sessionStorage.setItem('ebookName', oltEncrypt.storeEncodeText(name));
    state.ebookName = name;
  },
  // 伺服器參數檔
  setServerInfoConfig(state, obj) {
    sessionStorage.setItem('serverInfoConfig', oltEncrypt.storeEncodeObject(obj));
    state.serverInfoConfig = obj;
  },
  clearServerInfoConfig(state){
    sessionStorage.removeItem('serverInfoConfig');
    state.serverInfoConfig = null;
  },
  // 訪客專用，購物車數量+1
  plusOneShoppingCartNum(state) {
    console.log('plusOneShoppingCartNum3');
    if(state.userInfo == null){
      state.userInfo = {};
    }
    if(state.userInfo.products == null){
      state.userInfo.products = 1;
    }
    else {
      state.userInfo.products++;
    }
    console.log('state.userInfo : ', state.userInfo);
    sessionStorage.setItem('userInfo', oltEncrypt.storeEncodeObject(state.userInfo));
  },
  // 訪客專用，購物車數量-1
  minusOneShoppingCartNum(state) {
    if(state.userInfo == null){
      state.userInfo = {};
    }
    if(state.userInfo.products == null){
      state.userInfo.products = 0;
    }
    else {
      state.userInfo.products--;
    }
    sessionStorage.setItem('userInfo', oltEncrypt.storeEncodeObject(state.userInfo));
  },
  
  // 設定畫面轉跳權限資料
  setRouterViewPermission(state, obj) {
    sessionStorage.setItem('routerViewPermission', oltEncrypt.storeEncodeObject(obj));
    state.routerViewPermission = obj;
  },
  // 清空畫面轉跳權限資料
  clearRouterViewPermission(state){
    sessionStorage.removeItem('routerViewPermission');
    state.routerViewPermission = null;
  },
  
  // 設定 router 錯誤訊息
  setRouterErrorMessage(state, value) {
    sessionStorage.setItem('routerErrorMessage', oltEncrypt.storeEncodeText(value));
    state.routerErrorMessage = value;
  },
  // 清空 router 錯誤訊息
  clearRouterErrorMessage(state){
    sessionStorage.removeItem('routerErrorMessage');
    state.routerErrorMessage = null;
  },
  
  // 設定暫存活動序號
  setTempVoucher(state, value) {
    sessionStorage.setItem('tempVoucher', oltEncrypt.storeEncodeText(value));
    state.tempVoucher = value;
  },
  // 清空暫存活動序號
  clearTempVoucher(state){
    sessionStorage.removeItem('tempVoucher');
    state.tempVoucher = null;
  },
  
  // 設定目前影片資訊
  setCurrentVideo(state, value) {
    sessionStorage.setItem('currentVideo', oltEncrypt.storeEncodeObject(value));
    state.currentVideo = value;
  },
  // 清空目前影片資訊
  clearCurrentVideo(state){
    sessionStorage.removeItem('currentVideo');
    state.currentVideo = null;
  },
};

// 取得目前的登入狀態
export const getters = {
  //isAuthenticated: state => Boolean(state.user) || Boolean(sessionStorage.getItem('user')),
  isUserInfo(state) {
    if (state.userInfo) {
      return true;
    }
    return false;
  },
  isShoppingCart(state) {
    if (state.shoppingCart) {
      return true;
    }
    return false;
  },
  isOpenid(state) {
    if (state.openid) {
      return true;
    }
    return false;
  },
  isPayment(state) {
    if (state.payment) {
      return true;
    }
    return false;
  },  
  isKnowledgeVideo(state) {
    if (state.knowledgeVideo) {
      return state.knowledgeVideo;
    }
    return false;
  },

  isTempID(state) {
    if (state.tempID) {
      return state.tempID;
    }
    return false;
  },

  isHomeProfileCheck(state) {
    if (state.homeProfileCheck) {
      return true;
    }
    return false;
  },
  isPaymentChose(state) {
    if (state.paymentChose) {
      return state.paymentChose;
    }
    return false;
  },
  isEpid(state) {
    if (state.epid) {
      return true;
    }
    return false;
  },
  isCourseName(state) {
    if (state.courseName) {
      return true;
    }
    return false;
  },
  isExerciseName(state) {
    if (state.exerciseName) {
      return true;
    }
    return false;
  },
  isEbookName(state) {
    if (state.ebookName) {
      return true;
    }
    return false;
  },
  isServerInfoConfig(state) {
    if (state.serverInfoConfig) {
      return true;
    }
    return false;
  },
  isRouterViewPermission(state) {
    if (state.routerViewPermission) {
      return true;
    }
    return false;
  },
  isRouterErrorMessage(state) {
    if (state.routerErrorMessage) {
      return true;
    }
    return false;
  },
  isTempVoucher(state) {
    if (state.tempVoucher) {
      return true;
    }
    return false;
  },
  isCurrentVideo(state) {
    if (state.currentVideo) {
      return true;
    }
    return false;
  },
  
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: false
};
