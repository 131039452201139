/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
// 物件 ES6 封裝
// VUE 必需 EXPORT 出物件才能被 import 使用
// 如果 你想要 外部使用並且取用 它 RETURN 的值 就必需 NEW 它 
// EX：
//    let oAuth = new auth ;
//    var user = oAuth.isLogin();
//
import { oltConsole } from '@/lib/oltModule/oltConsole';
import { oltEncrypt } from '@/lib/oltEncrypt';
import { config } from '@/include/config';
import oStore from '@/store/index';
import md5 from 'js-md5';

// init oltConsole
export class oltUrl{

  constructor () {
    //const oltConsole = new oce() ; 
    oltConsole.log('oltUrl!!!');
    //oltConsole.sysExpireTime();
  }
  // eslint-disable-next-line class-methods-use-this

  // 記錄 之後 想要回來的 地方 ， 例如 登入後回到強制登入頁 
  locationUrl(store,urlObj){
    //console.log('oUrl locationUrl test :  ');
    store.dispatch('locationUrl',urlObj);
    //console.log(store.state);
  }

  cleanLocationUrl(store){
    store.dispatch('cleanLocationUrl');
    //console.log(store.state);
  }

  urlQuery(store,urlObj){
    store.dispatch('urlQuery',urlObj);
  }

  cleanUrlQuery(store){
    store.dispatch('cleanUrlQuery');
  }

  urlParams(store,urlObj){
    store.dispatch('urlParams',urlObj);
  }

  cleanUrlParams(store){
    store.dispatch('cleanUrlParams');
  }

  setRouterQuery(store, object) {
    store.dispatch('setRouterQuery', object);
  }

  cleanRouterQuery(store) {
    store.dispatch('cleanRouterQuery');
  }

  setRouterHistory(store, array) {
    store.dispatch('setRouterHistory', array);
  }

  cleanRouterHistory(store) {
    store.dispatch('cleanRouterHistory');
  }

  setRouterHistoryIndex(store, index) {
    store.dispatch('setRouterHistoryIndex', index);
  }

  cleanRouterHistoryIndex(store) {
    store.dispatch('cleanRouterHistoryIndex');
  }

  getRouterQuery(store){
    if(store.getters.isRouterQuery && 
      store.state.url != null &&
      store.state.url.routerQuery != null){
      return store.state.url.routerQuery;
    }
    return {}; 
  }

  getRouterHistory(store){
    if(store.getters.isRouterHistory && 
      store.state.url != null &&
      store.state.url.routerHistory != null){
      return store.state.url.routerHistory;
    }
    return []; 
  }

  getRouterHistoryIndex(store){
    if(store.getters.isRouterHistoryIndex && 
      store.state.url != null &&
      store.state.url.routerHistoryIndex != null){
      return store.state.url.routerHistoryIndex;
    }
    return 0;
  }  

  getRouterQueryByToken(store, token){
    if(store.getters.isRouterQuery && 
      store.state.url != null &&
      store.state.url.routerQuery != null &&
      store.state.url.routerQuery[token] != null &&
      store.state.url.routerQuery[token].data != null){
      return store.state.url.routerQuery[token].data;
    }
    return null; 
  }

  getRouterQueryByPageName(store, name){
    let routerHistory = this.getRouterHistory(oStore);

    if(routerHistory != null && routerHistory.length > 0){
      // 從 vuex 取得最近期的一筆紀錄
      for (let i = 0 ; i < routerHistory.length ; i++) {
        let temp = routerHistory[i];

        if(temp != null && temp.name != null && 
          (temp.name.indexOf(name) != -1) &&
          temp.data != null){
          return temp.data;
        }
      }
    }
    
    return null; 
  }

  // 回傳 前幾次瀏覽記錄的 路徑 , -1 ~ -n 往上 N層 
  getClickHistory(store,key){

  }

  // 查詢位置在 瀏覽記錄容器陣列的那一個索引上
  // 回傳 索引位置
  indexOfClickHistory(store,url){
  }

  // 寫入到 瀏覽記錄容器陣列尾端
  pushClickHistory(store,url){
  }

  // 移除 瀏覽記錄容器陣列的最後一筆
  popClickHistory(store,url){
  }

  // 寫入到 瀏覽記錄容器陣列前端
  unshiftClickHistory(store,object){
    let routerQuery = this.getRouterQuery(store);
    let routerHistory = this.getRouterHistory(store);

    if(object != null){
      if(object.key != null){
        routerQuery[object.key] = object;
        this.setRouterQuery(store, routerQuery);
      }
      // 將紀錄新增至第一筆
      routerHistory.unshift(object);
      this.setRouterHistory(store, routerHistory);
    }

    // 檢查紀錄數量上限
    this.checkRouterHistoryLimit(store);
  }

  // 移除瀏覽記錄容器陣列的第一筆  
  shiftClickHistory(store,url){
  }

  // 移除瀏覽記錄容器陣列 指定的索引位置及數量  
  // 容器，索引位置，數量
  spliceClickHistory(store,key,num){ 
  }

  checkRouterHistoryLimit(store){
    let routerHistory = this.getRouterHistory(store);
    let maxLength = 50;

    if(config != null && config.router != null && 
      config.router.max_histroy_length != null){
      // 從 config 取得上限
      maxLength = config.router.max_histroy_length;
    }

    if(routerHistory != null && routerHistory.length > maxLength){
      // 當數量超過上限，就從最後一個(最舊)資料刪除
      let routerQuery = this.getRouterQuery(store);

      for(let i = routerHistory.length ; i > maxLength ; i--){
        // 每次都從最後一筆進行移除
        let temp = routerHistory.pop();

        if(temp != null && temp.key != null && 
          routerQuery != null && routerQuery[temp.key] != null){
          delete routerQuery[temp.key];
        }
      }
    }
  }

  push(router, obj){
    if(router == null || obj == null){
      return;
    }

    let toName = null;
    let isParams = false;
    let isQuery = false;
    let toKey = null;
    let index = 0;
    let isName = false;
    let isPath = false;

    if(obj.name != null){
      // 檢查跳轉類型為 name
      toName = obj.name;
      isName = true;
    }
    if(obj.path != null && toName == null){
      // 檢查跳轉類型為 path
      toName = obj.path;
      isPath = true;
    }
    if(obj.params != null){
      // 檢查 params 是否有帶參數
      if(Object.entries(obj.params).length > 0){
        isParams = true;
      }
    }
    if(obj.query != null){
      // 檢查 query 是否有帶參數
      if(Object.entries(obj.query).length > 0){
        isQuery = true;
      }
    }

    if(!isParams && !isQuery){
      // 當沒有帶值 (透過麵包屑跳轉，只提供 name / path)      
      let routerHistory = this.getRouterHistory(oStore);
      let tempQuery = null;

      if(routerHistory != null && routerHistory.length > 0){
        // 從 vuex 取得最近期的一筆紀錄
        for (let i = 0 ; i < routerHistory.length ; i++) {
          let temp = routerHistory[i];

          if(temp != null && temp.name != null && temp.name == toName){
            tempQuery = temp;
            index = i;
            break;
          }
        }
      }

      if(tempQuery != null){
        if(tempQuery.key != null){
          toKey = tempQuery.key;
        }
        if(tempQuery.isName != null){
          isName = tempQuery.isName;
        }
        if(tempQuery.isPath != null){
          isPath = tempQuery.isPath;
        }
      }
    }

    if(toKey == null){
      // 查無紀錄，代表要新增紀錄

      let date = new Date();
      let time = date.getTime();

      toKey = md5(toName + '_' + time);

      let temp = { 
        name:toName, 
        data:obj, 
        key:toKey, 
        isParams:isParams,
        isQuery:isQuery,
        isName:isName, 
        isPath:isPath,
      };

      // 將紀錄新增到第一筆
      this.unshiftClickHistory(oStore, temp);
    }

    // 更新當前紀錄的位置
    this.setRouterHistoryIndex(oStore, index);

    if(toName != null && toName != '' &&
      toKey != null && toKey != ''){
      // 符合轉跳所需參數，進行轉跳

      // 增加一組隱性參數 Yves add SBDW5-2253
      let encodeStr = oltEncrypt.webUrlEncode(obj);

      if(isPath){
        router.push({ path : toName, query : { router_token: toKey, router_base: encodeStr }});
      }else if(isName){
        router.push({ name : toName, query : { router_token: toKey, router_base: encodeStr }});
      }
    }
  }

  convertShareWebUrl(origin, obj){
    let url = '';
    let page = '';
    let encodeStr = '';

    if(obj != null){
      // 將整包進行加密，包含 (path、query)
      encodeStr = oltEncrypt.webUrlEncode(obj);
      if(obj.path != null){
        page = obj.path;
      }
    }
    //console.log('encodeStr : ', encodeStr);

    // Yves debug encrypt
    //let decodeObj = oltEncrypt.webUrlDecode(encodeStr);
    //console.log('decodeObj : ', decodeObj);

    if(origin != null && origin != '' && page != null && page != '' && encodeStr != null && encodeStr != ''){
      url = origin + '/' + page + '?router_base=' + encodeStr;
    }
    else if(origin != null && origin != '' && page != null && page != ''){
      url = origin + '/' + page;
    }
    //console.log('url : ', url);

    return url;
  }
}