/*----------新版課程頁面----------*/
let Curriculum = [
  /*---電子書 Start---*/
  {
    // 後台整本預覽
    path: '/EBookAdminReview',
    name: 'EBookAdminReview',
    component: () => import('@/views/php/Curriculum/EBookAdminReview.vue')
  },
  {
    // 後台編輯電子書預覽
    path: '/EBookPageAdminReview',
    name: 'EBookPageAdminReview',
    component: () => import('@/views/php/Curriculum/EBookPageAdminReview.vue')
  },
  {
    // 前台閱讀
    path: '/EBookClientReview',
    name: 'EBookClientReview',
    component: () => import('@/views/php/Curriculum/EBookClientReview.vue')
  },
  {
    // 電子書清單
    path: '/EbookKnowledgeArchitecture',
    name: 'EbookKnowledgeArchitecture',
    component: () => import('@/views/php/Curriculum/EbookKnowledgeArchitecture.vue')
  },
  {
    // 電子書列表
    path: '/EbookLessonCurriculum',
    name: 'EbookLessonCurriculum',
    component: () => import('@/views/php/Curriculum/EbookLessonCurriculum.vue')
  },
  /*---電子書 End---*/

  /*---新版課程 Start---*/
  { 
    // 課程列表
    path: '/LessonDetailNew',
    name: 'LessonDetailNew',
    component: () => import('@/views/php/Curriculum/LessonDetailNew.vue')
  },
  {
    // 播放頁面
    path: '/VideoPlayerNew',
    name: 'VideoPlayerNew',
    component: () => import('@/views/php/Curriculum/VideoPlayerNew.vue')
  },
  /*---新版課程 End---*/

  /*---新版題庫評量 Start---*/
  {
    // 新版出卷範圍頁面
    path: '/ExamRangeNew',
    name: 'ExamRangeNew',
    component: () => import('@/views/php/Curriculum/ExamRangeNew.vue')
  },
  {
    // 新版出卷頁面
    path: '/ExamPaperSettingNewEdition',
    name: 'ExamPaperSettingNewEdition',
    component: () => import('@/views/php/Curriculum/ExamPaperSettingNewEdition.vue')
  },
  {
    // 考試頁面
    path: '/StartExamCurriculum',
    name: 'StartExamCurriculum',
    component: () => import('@/views/php/Curriculum/StartExamCurriculum.vue')
  },
  /*---新版題庫評量 End---*/

  /*---新版學習紀錄 Start---*/
  {
    // 課程
    path: '/LearnHistoryCurriculum',
    name: 'LearnHistoryCurriculum',
    component: () => import('@/views/php/Curriculum/LearnHistoryCurriculum.vue')
  },
  {
    // 成績單
    path: '/LearnHistoryUserExamPaperCurriculum',
    name: 'LearnHistoryUserExamPaperCurriculum',
    component: () => import('@/views/php/Curriculum/LearnHistoryUserExamPaperCurriculum.vue')
  },
  {
    // 成績單
    path: '/UserExamPaperRelationLessonCurriculum',
    name: 'UserExamPaperRelationLessonCurriculum',
    component: () => import('@/views/php/Curriculum/UserExamPaperRelationLessonCurriculum.vue')
  },
  {
    // 錯題記錄
    path: '/LearnHistoryUserExamCurriculum',
    name: 'LearnHistoryUserExamCurriculum',
    component: () => import('@/views/php/Curriculum/LearnHistoryUserExamCurriculum.vue')
  },
  {
    // 錯題練習
    path: '/CorrectUserExamCurriculum',
    name: 'CorrectUserExamCurriculum',
    component: () => import('@/views/php/Curriculum/CorrectUserExamCurriculum.vue')
  },
  {
    // 出卷紀錄
    path: '/LearnHistoryExamPaperCurriculum',
    name: 'LearnHistoryExamPaperCurriculum',
    component: () => import('@/views/php/Curriculum/LearnHistoryExamPaperCurriculum.vue')
  },
  {
    // 測驗雷達圖
    path: '/LearnHistoryExamDashboardCurriculum',
    name: 'LearnHistoryExamDashboardCurriculum',
    component: () => import('@/views/php/Curriculum/LearnHistoryExamDashboardCurriculum.vue')
  },
  {
    // 電子書
    path: '/LearnHistoryEbookCurriculum',
    name: 'LearnHistoryEbookCurriculum',
    component: () => import('@/views/php/Curriculum/LearnHistoryEbookCurriculum.vue')
  },
  
  /*---新版學習紀錄 End---*/
  
  /*---新版學習地圖 Start---*/
  {
    // 記錄總表
    path: '/LearningMapDetailCurriculum',
    name: 'LearningMapDetailCurriculum',
    component: () => import('@/views/php/Curriculum/LearningMapDetailCurriculum.vue')
  },
  
  /*---新版學習地圖 End---*/
  
  /*---新版解惑 Start---*/
  {
    // 解惑平台
    path: '/QuestionListCurriculum',
    name: 'QuestionListCurriculum',
    component: () => import('@/views/php/Curriculum/QuestionListCurriculum.vue')
  },
  {
    // Lite版解惑平台
    path: '/QuestionListLiteCurriculum',
    name: 'QuestionListLiteCurriculum',
    component: () => import('@/views/php/Curriculum/QuestionListLiteCurriculum.vue')
  },
  
  /*---新版解惑 End---*/
  
  /*---新版學習計畫 Start---*/
  {
    // 批量學習計畫
    path: '/AddLearnPlanCurriculum',
    name: 'AddLearnPlanCurriculum',
    component: () => import('@/views/php/Curriculum/AddLearnPlanCurriculum.vue')
  },
  {
    // 個別學習計畫
    path: '/AddCoursePlanCurriculum',
    name: 'AddCoursePlanCurriculum',
    component: () => import('@/views/php/Curriculum/AddCoursePlanCurriculum.vue')
  },
  
  /*---新版學習計畫 End---*/
];
  
export default Curriculum;