/* eslint-disable import/no-unresolved */
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'; 
import tw from '@/i18n/zh-TW.json';
import cn from '@/i18n/zh-CN.json';
import en from '@/i18n/en-US.json';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { config } from '@/include/config';

import swal from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

/** RD 自定義 CSS **/
import '@/assets/css/custom.css';

// eslint-disable-next-line import/no-unresolved
//import '@/styles/element/index.scss';
// 開發時全部都安裝等要封裝時再來減肥
//import ElementPlus from 'element-plus';
// 只安裝要使用的 元件
//import {ElPagination , ElTabs , ElTransfer , ElSwitch , ElRate} from 'element-plus';a


// Initialize Firebase


const app = initializeApp(config.firebase);
const analytics = getAnalytics(app);



const swalOptions = {
  confirmButtonColor: '#ea525e',
  cancelButtonColor: '#333333',
};

const i18n = createI18n({
  legacy: false,
  locale: 'zh-TW',
  fallbackLocale: 'zh-TW',
  messages: {
    'zh-TW': tw,
    'zh-CN': cn,
    'en-US': en,
  }
});

router.afterEach((to, from) => {
  //ga('set', 'page', to.path)
  //ga('send', 'pageview')
  // console.log(to);
  // console.log(to.name);
  logEvent(analytics, 'page_view', { page_title: to.fullPath});
  /*
	logEvent(analytics, 'select_content', {
	  content_type: 'image',
	  content_id: 'P12453',
	  items: [{ name: 'Kittens' }]
	});*/

});

createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(swal,swalOptions)
  //.use(ElPagination)
  //.use(ElTabs)
  //.use(ElTransfer)
  //.use(ElSwitch)
  //.use(ElRate)
  //.use(ElementPlus)
  .mount('#app');
