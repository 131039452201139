import { oltConsole } from '@/lib/oltModule/oltConsole';
import { oltJavaScriptError } from '@/lib/oltJavaScriptError';

export class oltCookieModule {
    constructor() {
        oltConsole.log('Init oltCookie!!!');
        this.key = "oltAcceptCookie";
    }    
    cookieExpires(){
        try{
            let year = 24 * 60 * 60 * 365 * 1000; // 長度一年
            let expires = (new Date(Date.now()+ year)).toUTCString();
            return expires;
        }catch(err){
            oltJavaScriptError.exceptionLog('oltCookie', 'cookieExpires', err);
        }finally {
        }
        return '';
    };
    getAcceptCookie(){
        return this.getCookie(this.key);
    }
    setAcceptCookie(value){
        return this.setCookie(this.key, value);
    }
    getCookie(cookieName){
        try{
        let name = cookieName + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
        }catch(err){
            oltJavaScriptError.exceptionLog('oltCookie', 'getCookie', err);
        }finally {
        }
    }    
    setCookie(name, value){
        try{
            if(name && name != ''){
                let expires = "expires=" + this.cookieExpires();
                if(value && value != ''){
                    document.cookie = name + "=" + encodeURIComponent(value) + ";" + expires + ";path=/";
                }else{
                    document.cookie = name + "=;" + expires + ";path=/";
                }
            }
        }catch(err){
            oltJavaScriptError.exceptionLog('oltCookie', 'setCookie', err);
        }finally {
        }
    }  
}

// eslint-disable-next-line new-cap
const oltCookie = new oltCookieModule();

export { oltCookie };